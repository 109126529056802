<div class="bg-white rounded">
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">Mi Perfil de Usuario</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<form [formGroup]="form">
				<div class="row">
					<div class="form-group col-12 col-lg">
						<label for="nombre">Nombre*</label>
						<input type="text" class="form-control" id="nombre" formControlName="nombre"
							[ngClass]="{'is-invalid': form.controls['nombre'].errors && form.controls['nombre'].touched}">
						<div class="invalid-feedback">
							Debe ingresar un valor en el campo
						</div>
					</div>
					<div class="form-group col-12 col-lg">
						<label for="apellido">Apellido*</label>
						<input type="text" class="form-control" id="apellido" formControlName="apellido"
							[ngClass]="{'is-invalid': form.controls['apellido'].errors && form.controls['apellido'].touched}">
						<div class="invalid-feedback">
							Debe ingresar un valor en el campo
						</div>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-12 col-lg">
						<label for="custom-use">Nombre de usuario*</label>
						<input type="text" autocomplete="off" class="form-control" id="custom-use"
							formControlName="username"
							[ngClass]="{'is-invalid': form.controls['username'].errors && form.controls['username'].touched}"
							name="custom-use">
						<div class="invalid-feedback">
							Debe ingresar un valor en el campo
						</div>
					</div>
					<div class="form-group col-12 col-lg">
						<label for="rol_id">Rol*</label>
						<select class="form-select" id="rol_id" formControlName="rol_id"
							[ngClass]="{'is-invalid': form.controls['rol_id'].errors && form.controls['rol_id'].touched}">
							<option value=""></option>
							<option value="1">Administrador General</option>
							<option value="2">Administrador Arica</option>
							<option value="3">Administrador Santiago</option>
							<option value="4">Administrador de Finanzas</option>
							<option value="5">Administrador de Bodegas</option>
							<option value="6">Cajero</option>
							<option value="7">Vendedor</option>
						</select>
						<div class="invalid-feedback">
							Debe ingresar un valor en el campo
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="email">Correo electrónico*</label>
					<input type="email" class="form-control" id="email" formControlName="email"
						[ngClass]="{'is-invalid': form.controls['email'].errors && form.controls['email'].touched}">
					<div class="invalid-feedback">
						Debe ingresar un valor en el campo
					</div>
				</div>
				<div class="row">
					<div class="form-group col-12 col-lg">
						<label for="fono1">Fono principal</label>
						<input type="text" class="form-control" id="fono1" formControlName="fono1">
					</div>
					<div class="form-group col-12 col-lg">
						<label for="fono2">Fono secundario</label>
						<input type="text" class="form-control" id="fono2" formControlName="fono2">
					</div>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-secondary"
				(click)="activeModal.dismiss('Cancel click')">Cancelar</button>
		</div>
	</div>
</div>