<div class="bg-white rounded">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title"></h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="form">
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <div class="title">
                        Contraseña
                    </div>
                    <div class="value">
                        <div class="form-group">
                            <input class="form-control form-control-lg" type="text" readonly formControlName="password">
                        </div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="accept">
                        <label class="form-check-label" for="flexCheckDefault">
                            He copiado la contraseña en un lugar seguro.
                        </label>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success text-left" (click)="guardar()" [attr.disabled]="(form.value.accept) ? null : true">Generar</button>
            <button type="button" class="btn btn-Secondary" (click)="activeModal.dismiss('Cancel click')">Cancelar</button>
        </div>
    </div>
</div>