<div class="bg-white rounded">
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">Cambiar Contraseña</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<form [formGroup]="form">
				<div class="form-group">
					<label for="oldPass">Contraseña Actual</label>
					<input type="password" autocomplete="off" class="form-control" id="oldPass" formControlName="oldPass" [ngClass]="{'is-invalid': f['oldPass'].errors && f['oldPass'].touched}">
					<div *ngIf="f['oldPass'].errors" class="invalid-feedback">
						<div *ngIf="f['oldPass'].errors['required']">La contraseña no puede ser vacía.</div>
					</div>
				</div>

				<div class="form-group">
					<label for="newPass">Nueva Contraseña</label>
					<input type="password" autocomplete="off" class="form-control" id="newPass" formControlName="newPass" [ngClass]="{'is-invalid': f['newPass'].errors && f['newPass'].touched}">
					<div *ngIf="f['newPass'].errors" class="invalid-feedback">
						<div *ngIf="f['newPass'].errors['required']">La contraseña no puede ser vacía.</div>
					</div>
				</div>

				<div class="form-group">
					<label for="repeatPass">Repetir Contraseña</label>
					<input type="password" autocomplete="off" class="form-control" id="repeatPass" formControlName="repeatPass" [ngClass]="{'is-invalid': f['repeatPass'].errors && f['repeatPass'].touched}">
					<div *ngIf="f['repeatPass'].errors" class="invalid-feedback">
						<div *ngIf="f['repeatPass'].errors['required']">La contraseña no puede ser vacía.</div>
						<div *ngIf="f['repeatPass'].errors['mustMatch']">Las contraseñas no coinciden.</div>
					</div>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-success" (click)="guardar()">Guardar</button>
		</div>
	</div>
</div>